import {
    Box,
    Flex,
    Stack,
    Heading,
    Text,
    Container,
    Input,
    Button,
    SimpleGrid,
    Avatar,
    AvatarGroup,
    useBreakpointValue,
    IconProps,
    Icon,
} from '@chakra-ui/react';
  
    export default function _HomeLayout(props) {
        return (
            <Box minH="100vh">
          
            <Box ml={{ base: 0, md: 38 }} p="2" backgroundColor={"white"}>
                {props.children}
            </Box>
        </Box>
        );
        }

