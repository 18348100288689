import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as OSInspectionService from '../../Services/OSInspectionService'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { NumberInput, NumberInputField,NumberInputStepper,NumberIncrementStepper,NumberDecrementStepper,Textarea,Text,FormControl,SimpleGrid,IconButton,Center, Select, FormLabel, HStack, FormErrorMessage, Input, Checkbox, useDisclosure, Stack, ButtonGroup, Tfoot, Box, Table as C_Table, Button, Thead, Tbody, Td, Tr, Th, Divider,Table,  TableContainer, TableCaption, Spacer, Grid,  } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import { Radio, RadioGroup,useRadio,useRadioGroup } from '@chakra-ui/react'
import {AlertDialog,AlertDialogBody,AlertDialogFooter,AlertDialogHeader,AlertDialogContent,AlertDialogOverlay,AlertDialogCloseButton} from '@chakra-ui/react'
import { color } from 'framer-motion';

function DashBoardComponent() {
    const { register, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onclick" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose, onOpen: onScOpen, onClose: onScClose } = useDisclosure();
    const [issubmitted, SetisSubmitted] = useState([]);
    const [CustomerDetail, SetCustomerDetail] = useState();
    const [QuestionDetails, SetQuestionDetail] = useState([]);
    const selectedCustomerid = useRef(); 
    const [value, setValue] = React.useState()  
    const [list, SetAnswerDetail] =  React.useState();
    const [selectedOption, setSelectedOption] = useState('');
    const[IsYesSelected,SetYes]=useState(false);
    //const [list, setList] = React.useState(AnswerDetails);
    const _ = require("lodash");
    const groups = _.groupBy(QuestionDetails, 'Category');
    const queryParameters = new URLSearchParams(window.location.search)
    const resourceid = queryParameters.get("resourceid")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    const[isvalidform,Setvalidform]=useState(true);

     useEffect(() => {
      // const query = new URLSearchParams(this.props.location.search);
      //  resourceid = query.get('resourceid');
       
     }, [])


function handleToggleCompletetextarea(event,id)
{
  debugger;

  const newdataList = list.map((item) => {
    if (item.Id === id ) {  
      debugger;  
      const updatedItem = {
        ...item,
       
    Additional_Remark:  (event.target.value),
    }
    return updatedItem;
  }
  return item;  
    
   
  })
  SetAnswerDetail(newdataList);
    SetQuestionDetail(newdataList);
  
};






    function handleToggleComplete(event,id) {
        debugger;
        const newList = list.map((item) => {
           //debugger;
           
          if (item.Id === id ) {        
            
            debugger;
            item.Additional_Remark="";
                if(item.Question_Type == 'Radio' && item.Is_MultiOption == false)
                {   
                    setSelectedOption(event.target.value);
                    const updatedItem = {
                        ...item,
                        Answer_Text:  (event.target.value),                      
                      
                      };                     
                                    
                      return updatedItem;  
                         
                }      
                
                if(item.Is_MultiOption == true && event.target.value == "Yes" && (item.Additional_Remark == "" || item.Additional_Remark == null) )
                {   const updatedItem = {
                     ...item,
                  Additional_Remark:  '',  
                  Answer_Text:  "Yes",           
                 };
                 return updatedItem; 
                }
                else if(item.Is_MultiOption == true && (event.target.value == "No") )
                {
                  const updatedItem = {
                    ...item,
                Additional_Remark:  "",  
                 Answer_Text:  event.target.value,           
                };
                return updatedItem; 
                }
                else if(item.Is_MultiOption == true && (item.Additional_Remark != ""|| event.target.value !="" ) )
                {
                  const updatedItem = {
                    ...item,
                Additional_Remark:  (event.target.value),  
                         
                };
                return updatedItem; 
                }
                               
               
                if(item.Question_Type == 'TextBox')
                { const updatedItem = {
                    ...item,
                    Answer_Text: (event.target.value) ,
                 
                  };           
                  return updatedItem;
                }   
                if(item.Question_Type == 'Number')
                {
                 var value= event.target.value.replace(/\D/g, '');
                  const updatedItem = {
                    ...item,
                    Answer_Text: (value) ,
                 
                  };           
                  return updatedItem;
                }          
          }
    
          return item;
        });
    
        SetAnswerDetail(newList);
        SetQuestionDetail(newList);
      }
        
     


    const SaveCheckListDetail = () => {
        //debugger;
         var isvalidated = true;
         var ismultivalidated =true;
         var errormessage="";
         //var finalList =  (e => e.list.some(a => a.Question_Type.startsWith("ABC")));
        list.map((item) => {
          //debugger;        
          if(item.Question_Type == 'Radio' && item.Answer_Text == undefined || (item.Question_Type =="Radio" && item.Answer_Text =='' || item.Question_Type =="Radio" && item.Answer_Text == null))
           {
            isvalidated = false;         
            errormessage="Please mark all the mandatory questions.";
           }  

          else if(item.Is_MultiOption && item.Answer_Text == "Yes" && item.Question_Title !="Rodent/Fly Catcher Map" &&(item.Category_Id == 4 || item.Category_Id == 5) && (item.Additional_Remark =='' ||  item.Additional_Remark == undefined || item.Additional_Remark == null))
           {
            isvalidated = false;        
            ismultivalidated=false;
            errormessage="Please enter comments for yes-selected questions.";
           }    
           else if(item.Is_MultiOption && item.Answer_Text == "No" && (item.Category_Id ==2 || item.Question_Title =="Rodent/Fly Catcher Map" || item.Question_Id ==29 )  && (item.Additional_Remark =='' ||  item.Additional_Remark == undefined || item.Additional_Remark == null))
           {
            isvalidated = false;        
            ismultivalidated=false;
            errormessage="Please enter comments for no-selected questions.";
           }     
          
          });               
         if(isvalidated && ismultivalidated)
                  {  
                  
                   if (window.confirm("Do you want to submit the form?") == true) {
                    debugger;
                    OSInspectionService.SaveCheckListDetails(list,resourceid).then(data => {
                      if (data.data.IsSuccess) {
                          toast.success("Success.");
                        Searchcustomer(list[0].Customer_id);
                        SetQuestionDetail([]);
                        SetAnswerDetail([]);
                         
                      } else {
                          toast.error("Somthing went wrong.");
                      }
                  }).catch(error => {
                      toast.error("Somthing went wrong.");
                  })

                  } else {
                    return false
                  }
                  }
                  else{
                    toast.error(errormessage);
                  }
                    
                }

                  const SubmitCheckListDetail = () => {

                   
                  }
                 
                 
               
              
                 
             


    const Searchcustomer = (value) => {
        debugger; 
        SetQuestionDetail([]);
        SetAnswerDetail([]);
      if(value == ''|| value == undefined)
      value = selectedCustomerid.current.value.trim();;
        
      if(value == '')
      {
        toast.error("CustomerId Cannot be empty");
      }
      else{

            OSInspectionService.GetAllOsInspectionQuestions(value).then(data => {
            if (data.data.IsSuccess) {
                debugger;
                Array.from(document.querySelectorAll("input")).forEach(
                    input => (input.value = "")
                  );                
                setSelectedOption('');
                SetisSubmitted(data.data.Data[0].Is_Submitted);
                SetQuestionDetail(data.data.Data);
                SetAnswerDetail(data.data.Data);
               var x=groups;
               } else {
                Array.from(document.querySelectorAll("input")).forEach(
                  input => (input.value = "")
                ); 
                toast.error("invalid Customer Id");
                SetisSubmitted([]);
                SetQuestionDetail([]);
                SetAnswerDetail([]);
            }
        }).catch(error => {
            toast.error("Somthing went wrong.");
        })
    }
  }
    
          
          function getUnique(arr, index) {

            const unique = arr
                 .map(e => e[index])
          
                 // store the keys of the unique objects
                 .map((e, i, final) => final.indexOf(e) === i && i)
            
                 // eliminate the dead keys & store unique objects
                .filter(e => arr[e]).map(e => arr[e]);      
          
             return unique;
          }
      


    return (
        <ContainerComponent  Title ="OS Inspection Form" style={{ color: '#38A169' }} Icon={<EditIcon style={{color : '#38A169'}} />}   >
          <title>
            
          </title>
            <HStack>
                <Box w='65%' p={2}>
                    <FormControl >
                        <Input  placeholder='Enter Customerid' ref={selectedCustomerid}  />
                    </FormControl>
                </Box>
                 <Box w='35%' paddingLeft={2}>
                 <IconButton onClick={() => Searchcustomer()} colorScheme='green'  aria-label='Search database'  icon={<SearchIcon />}
/>
                </Box> 
            </HStack>         
{QuestionDetails.length>0 ? (

<TableContainer>
            <Spacer />
      <Table size="sm" variant='simple' >
    <Thead>
      <FormControl>
        <FormLabel style={{ color: '#027148',fontWeight: 'bold' }}  paddingLeft={4}>General Info</FormLabel>
      </FormControl>   
     
        <Tr>      
            <Th>Customer Id</Th>
            <Th>Shipping Region</Th>          
         
        </Tr>
        <Tr>
        <Td>{QuestionDetails[0].Customer_id}</Td>
            <Td>{QuestionDetails[0].Shipping_Region}</Td>  
        </Tr>
        <Tr>
        <Th>Account Name</Th>
            <Th>Monthly SV</Th>  
       </Tr>
       <Tr>       
        <Td  style={{ whiteSpace: 'pre-wrap' }} width={'10%'}  >{QuestionDetails[0].Account_Name}</Td>
        <Td >{QuestionDetails[0].Monthly_Sv}</Td>  
        </Tr>
        <Tr>
        <Th>Service Group Code</Th>           
       </Tr>
       <Tr>       
          <Td >{QuestionDetails[0].Service_Group_Code}</Td>  
        </Tr>
              
    </Thead>
     

</Table>
</TableContainer> 
):<></>
}           


            <ContainerComponent  onSave={handleSubmit(SaveCheckListDetail)} >
           
                <Stack spacing={4}>
                {/* {Object.keys(groups).map(key => (
                 <FormLabel>{key}</FormLabel>
                ))} */}
                {QuestionDetails.map((question,index) => {  //debugger;  
return (               
  <Stack key={index}>                        
  {question.Question_Type =="Radio"?(                            
   <Stack> 
    <SimpleGrid columns={1} spacing={10}>            
  <Box position={'relative'} w='100%' paddingLeft={5}>   
                   
  <Divider />
      <FormLabel style={{ color: '#027148',fontWeight: 'bold' }}> {question.Category ? question.Category :<></>}</FormLabel>
      <FormControl isRequired >  
          <FormLabel>  {question.Question_Title}</FormLabel>
       </FormControl>
         <FormControl>
          <RadioGroup  defaultValue={question.Answer_Text} >
          <Stack spacing={2} direction='row'>  

       
      {(() => {
        if (question.Question_Title =="Cockroach Infestation ?" || question.Question_Title=="Rodent Infestation ?") {
          return (
            <div>
             <Radio  name="High Infestation" value={"High Infestation"} isDisabled={question.Is_Submitted == true} colorScheme='green'   onChange={(e) => handleToggleComplete(e,question.Id) }>High Infestation &nbsp; </Radio>
             <Radio name="Low Infestation" value={"Low Infestation"} isDisabled={question.Is_Submitted == true}  colorScheme='green'   onChange={(e) => handleToggleComplete(e,question.Id)} >Low Infestation</Radio>
          </div>
          )
        } else {
          return (
            <div>
            <Radio  name="Yes" value={"Yes"} isDisabled={question.Is_Submitted == true} colorScheme='green'   onChange={(e) => handleToggleComplete(e,question.Id) }>Yes &nbsp;</Radio>
          <Radio name="No" value={"No"} isDisabled={question.Is_Submitted == true}  colorScheme='green'   onChange={(e) => handleToggleComplete(e,question.Id)} >No &nbsp;</Radio>
         {question.Is_MultiOption ==false && question.Question_Title != "SU Wise Checklist Required? (Large Site)"?
         <Radio name="N/A" value={"N/A"} isDisabled={question.Is_Submitted == true} colorScheme='green'    onChange={(e) => handleToggleComplete(e,question.Id)}>N/A</Radio>
     
          :   <></> }
              </div>
          )
        } 
      })()}

                                              
         

          </Stack>
          </RadioGroup> 
          </FormControl>
          <FormControl>
          {(question.Is_MultiOption == true && question.Answer_Text=="No" && (question.Category_Id ==2 ||question.Question_Id == 29 || question.Question_Title =="Rodent/Fly Catcher Map")  ) ||  (question.Is_MultiOption==true && question.Answer_Text =="Yes" && question.Question_Title !="Rodent/Fly Catcher Map" && (question.Category_Id == 4 || question.Category_Id == 5)) ?
            <Textarea size='md' disabled={question.Is_Submitted == true}  name={(question.Additional_Remark)} id={(question.Id)} value={question.Additional_Remark} placeholder={"Enter Text Here"} onChange={(e) => handleToggleCompletetextarea(e,question.Id)}/>
          :
        <></>
          }
          </FormControl>        
          
     
  </Box>  
  </SimpleGrid> 
  </Stack>              
  ):
  <Stack>
  <Box position={'relative'} w='100%' paddingLeft={5}>
  <Divider />
  <FormControl >
  <FormLabel style={{ color: '#027148',fontWeight: 'bold' }} >{question.Category}</FormLabel>
  <FormLabel></FormLabel>   
      <FormLabel> {question.Sub_Category ? (question.Sub_Category + " - " ) :<></>} {question.Question_Title}</FormLabel>    
     
     
  {question.Question_Type == "Number"?( 
          <Textarea size='md' disabled={question.Is_Submitted == true}  name={(question.Answer_Text)} id={(question.Id)} value={question.Answer_Text} placeholder={"Enter Number"} onChange={(e) => handleToggleComplete(e,question.Id)}/>
   
        
  ):
      <Textarea size='md' disabled={question.Is_Submitted == true}  name={(question.Answer_Text)} id={(question.Id)} value={question.Answer_Text} placeholder={"Enter Text Here"} onChange={(e) => handleToggleComplete(e,question.Id)}/>
  }
  </FormControl>
</Box>
</Stack>
  }                     
</Stack>
)
  }
)}                       
              <Center>
                   {QuestionDetails.length>0 && issubmitted == false  ?(
   <Button style={{ backgroundColor: '#38A169', }}  width="100px" type='submit' onClick={handleSubmit(SaveCheckListDetail)} colorScheme="whatsapp">Submit</Button>  
                   ):                   
                   <></>
                   }
                    </Center>  
                </Stack>
           
            </ContainerComponent>
                     
        </ContainerComponent >
    )
}


export default DashBoardComponent