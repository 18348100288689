//export const API_DOMAIN = "http://localhost:56116/api/";
export const API_DOMAIN = "https://run.hicare.in/TaskService/api/";

export const API_HEADER = {
    headers: {
        'Content-Type': 'application/json',
         'Access-Control-Allow-Origin': '*'
    }
};

export const FILE_UPLOAD_API_HEADER = {
    headers: {
        'content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
    }
};