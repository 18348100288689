import React, { useState, useEffect } from 'react'
import { Flex, Box, Spacer, Heading, Button, Container } from '@chakra-ui/react'
import { Toaster } from 'react-hot-toast'
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent'

function ContainerComponent(props) {
    return (
        <Flex>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Box w={"full"} padding={"1"} boxShadow={"lg"}>
                <Flex>
                    {props.ShowHeader == "false" ?
                        <></> :
                        <Heading as="h6" size={"sm"} lineHeight={1.0} fontSize={{ base: '1xl', sm: 'xl' }} boxShadow={"sm"} padding={"2"}>
                            <Flex color={"whatsapp.400"}>
                                <Box>{props.Icon} {" "}</Box>
                                <Spacer />
                                <Box paddingLeft={"2"}>{props.Title}</Box>
                            </Flex>
                        </Heading>}
                    <Spacer />

                    {
                        props.ShowDownloadAction == "true" ?
                            (<Button leftIcon={props.DownloadBtnIcon} colorScheme='whatsapp' variant='solid' onClick={() => props.AddDownloadAction()}>
                                {props.DownloadBtnTitle}
                            </Button>) :
                            (<></>)
                    }
                    {
                        props.ShowAction == "true" ?
                            (<Button leftIcon={props.BtnIcon} colorScheme='whatsapp' variant='solid' onClick={() => props.AddNewAction()}>
                                New {props.ButtonTitle}
                            </Button>) :
                            (<></>)
                    }
                </Flex>

                <Box paddingTop={"4"}>
                    <LoadingSpinnerComponent></LoadingSpinnerComponent>
                    {props.children}
                </Box>
            </Box>
        </Flex>
    )
}

export default ContainerComponent