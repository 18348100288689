const initialdata = {
    userData: { },
    isAuthenticated: false
}

const asyncReducer = (state = initialdata, action) => {
    
    switch (action.type) {
        case "set-user-info":
            //debugger;
            return Object.assign({}, state, {
                userData: action.user.User,
                AccessToken :action.user.AccessToken,
                isAuthenticated: true
            });
        case "logout":
            return initialdata;
        default:
            return state;

    };
}

export default asyncReducer;