import { useColorModeValue } from '@chakra-ui/color-mode'
import { Box } from '@chakra-ui/layout'
import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { store } from '../Store/store'
import { useDisclosure } from '@chakra-ui/react';

function _DashBoardLayout(props) {
  
      
        return <Navigate to="/dashboard" />
    
}

export default _DashBoardLayout