import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import _DashboardLayout from './PageLayout/_DashBoardLayout';
import DashBoardComponent from './Components/DashBoard/DashBoardComponent';
import DashBoard from './Pages/DashBoardPage';
import _HomeLayout from './PageLayout/_HomeLayout';
function App() {
    return (
    <BrowserRouter>
      <Routes>

        <Route
          path="/"
          element={
            <_DashboardLayout>
              <DashBoard></DashBoard>
            </_DashboardLayout>
          }
        />
       
        <Route
       
          path="/dashboard"
          element={
            <_HomeLayout>
              <DashBoard></DashBoard>
              </_HomeLayout>
          }
        />

       
      </Routes>
    </BrowserRouter>
  );
}
export default App;
