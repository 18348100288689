import axios from 'axios'
import * as CommonVariables from '../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';                       


//for usermaster list
export const GetAllOsInspectionQuestions = async (customerid) => {
    //debugger;
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `OSInspection/GetAllOsInspectionQuestions?account_id=${customerid}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for detail by id
// export const GetAllOsInspectionQuestions = async (customerid) => {
//     debugger;
//     var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `OSInspection/GetAllOsInspectionQuestions?account_id=${customerid}`, CommonVariables.API_HEADER)).then(response => response);
//     return result;
// }

//for saving new user detail
export const SaveCheckListDetails = async (answer,resourceid) => {
 //debugger;
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `OSInspection/SaveOSInspectionAnswers?resourceid=` + resourceid,JSON.stringify(answer), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

